<route>
{
  "meta": {
    "auth": "productActivity"
  }
}
</route>

<template>
  <div>
    <el-card class="searchContainer">
      <div class="desc">筛选查询</div>
      <el-row :gutter="20">
        <el-col :span="6">
          <span class="searchText">活动名称</span>
          <el-input
            class="searchInput"
            @keyup.enter.native="getList"
            v-model.trim="searchValue.name"
            placeholder="活动名称"
            clearable
          >
          </el-input>
        </el-col>
        <el-col :span="6">
          <el-button type="info" @click="resetSearch()">重置</el-button>
          <el-button type="primary" @click="getList()"> 搜索</el-button>
        </el-col>
      </el-row>
    </el-card>
    <el-card class="tableContainer">
      <div style="margin: 14px 7px" v-if="$store.getters['user/isAllArea']">
        <span class="text">活动对应城市</span>
        <el-select class="city" placeholder="请选择城市" @change="getList" v-model="areaId">
          <el-option v-if="areaId === -1" label="全国" :value="-1"></el-option>
          <el-option
            v-for="item in $store.getters['user/userAreaInfoListWithoutGlobal']"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div slot="header" class="clearfix">
        <span>活动列表</span>
        <el-button @click="edit('')" type="success" style="float: right; ">添加</el-button>
      </div>

      <el-table :data="list" v-loading="loading" style="width: 100%">
        <el-table-column align="center" label="id" prop="id"></el-table-column>
        <el-table-column align="center" label="活动背景图">
          <template slot-scope="scope">
            <img class="avatar" :src="scope.row.backgroundImageUrl | thumbMedium" />
          </template>
        </el-table-column>

        <el-table-column label="活动名称" align="center" prop="name"> </el-table-column>
        <el-table-column align="center" prop="areaId" label="地区">
          <template slot-scope="scope">
            {{ scope.row.areaId | formatArea }}
          </template>
        </el-table-column>
        <el-table-column prop="description" align="center" label="活动描述"> </el-table-column>

        <el-table-column align="center" prop="productNum" label="关联商品数"> </el-table-column>
        <el-table-column align="center" prop="displayStatus" label="活动开关">
          <template slot-scope="scope">
            <el-switch :inactive-value="0" :active-value="1" v-model="scope.row.displayStatus" :disabled="true">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="" label="活动时间" width="250">
          <template slot-scope="scope">
            <div>活动开始时间：{{ scope.row.startTime | formatTime }}</div>
            <div>活动结束时间：{{ scope.row.endTime | formatTime }}</div>
          </template>
        </el-table-column>

        <el-table-column align="center" label="操作" width="300px">
          <template slot-scope="scope">
            <el-dropdown
              @command="relationProduct(scope.row, $event)"
              style="margin-right:5px"
              v-if="scope.row.productConnectWay !== 2"
            >
              <el-button type="text" size="small">
                关联
                <i class="el-icon-arrow-down"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="product">关联商品</el-dropdown-item>
                <el-dropdown-item command="classify">关联分类</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>

            <el-button
              v-if="scope.row.productConnectWay === 2 && scope.row.productSortTypeShow === 0"
              @click="relationProduct(scope.row, '', ' dragger ')"
              type="text"
              size="small"
              >拖动排序</el-button
            >
            <el-button @click="edit(scope.row)" type="text" size="small">编辑</el-button>
            <el-button @click="relationProduct(scope.row, '', 'check')" type="text" size="small">查看</el-button>

            <el-button @click="showDelete(scope)" class="deleteBtnText" type="text" size="small">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="footer">
        <el-pagination
          :current-page="currentPage"
          :page-sizes="pageSizes"
          :page-size="pageSize"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          class="pagination"
        >
        </el-pagination>
      </div>
    </el-card>

    <el-dialog :close-on-click-modal="false" :title="title" :visible.sync="Dialog" width="30%" class="activityDialog">
      <el-form ref="activityForm" :rules="firstRules" :model="activityForm" label-width="140px">
        <el-form-item label="活动背景图" prop="backgroundImageUrl">
          <bm-upload type="system" v-model="activityForm.backgroundImageUrl"> </bm-upload>
        </el-form-item>
        <el-form-item label="活动名称" prop="name">
          <el-input v-model.trim="activityForm.name"></el-input>
        </el-form-item>
        <!-- <el-form-item label="显示样式">
          <el-radio-group v-model="activityForm.showStyle">
            <el-radio :label="1">左右滑动</el-radio>
            <el-radio :label="2">纵向两列</el-radio>
          </el-radio-group>
        </el-form-item> -->
        <el-form-item label="商品排序类型">
          <el-select v-model="activityForm.productSortTypeShow" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="小程序最高展示数">
          <el-input v-model.trim="activityForm.productShowNum">
            <template slot="个"></template> <bm-tip>填0则不限制</bm-tip>
          </el-input>
        </el-form-item>
        <el-form-item label="是否过滤售罄商品">
          <el-radio-group v-model="activityForm.hideSoldOutProduct">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="商品添加方式">
          <el-radio-group v-model="activityForm.productConnectWay" :disabled="!!activityForm.id">
            <el-radio :label="1">手动添加</el-radio>
            <el-radio :label="2">自动添加</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="添加规则" v-show="activityForm.productConnectWay === 2">
          <el-select :disabled="!!activityForm.id" v-model="activityForm.autoConnectType" placeholder="请选择">
            <el-option label="一上架就添加" :value="1"> </el-option>
            <el-option label="按上架时间" :value="2"> </el-option>
            <el-option label="按下架时间" :value="3"> </el-option>
            <el-option label="按销量" :value="4"> </el-option>
          </el-select>
          <template v-if="activityForm.autoConnectType !== 1">
            <el-input-number
              v-model="activityForm.filterDays"
              :disabled="!!activityForm.id"
              :min="1"
              :max="30"
              style="width: 100px;margin: 0 10px"
            ></el-input-number>
            <label>天内</label>
          </template>
        </el-form-item>
        <el-form-item label="关联商品数" v-show="activityForm.autoConnectType === 4">
          <el-input-number
            v-model="activityForm.filterNum"
            :disabled="!!activityForm.id"
            :min="1"
            style="width: 140px;"
          ></el-input-number>
          <p class="tips">活动商品的关联数，填写后不可更改</p>
        </el-form-item>
        <el-form-item label="微页面展示商品数">
          <el-input v-model.trim="activityForm.miniPageShowNum">
            <template slot="个"></template>
          </el-input>
          <p class="tips">可填写数不能大于关联商品数</p>
        </el-form-item>

        <el-form-item label="活动开关">
          <el-switch :inactive-value="0" :active-value="1" v-model="activityForm.displayStatus"> </el-switch>
        </el-form-item>

        <el-form-item label="活动描述" prop="description">
          <el-input v-model.trim="activityForm.description"></el-input>
        </el-form-item>
        <!-- <el-form-item label="活动排序" prop="sortNo">
          <el-input-number
            v-model="activityForm.sortNo"
            controls-position="right"
            :min="0"
            :step="1"
            :max="9999"
          ></el-input-number>
        </el-form-item> -->
        <el-form-item label="活动时间" prop="startTime">
          <el-date-picker
            v-model="time"
            @change="handleTimeChange"
            type="daterange"
            value-format="timestamp"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button round @click="Dialog = false">取消</el-button>
        <el-button round type="primary" @click="confirm">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 删除组件 -->
    <bm-deleteItem
      url="boom-center-admin-service/sysAdmin/productGroup/"
      :timestamp="timestamp"
      @successDelete="successDelete"
    ></bm-deleteItem>
  </div>
</template>

<script>
import { pagination } from '@/mixin/pagination'
import { deleteRowItem } from '@/mixin/deleteRowItem'
import { statistics } from '@/api/statistics'
export default {
  mixins: [pagination, deleteRowItem],
  data() {
    return {
      areaId: -1,
      time: [],
      searchValue: {
        memberNickname: ''
      },
      options: [
        {
          value: 1,
          label: '销量-从高到低'
        },
        {
          value: 2,
          label: '销量-从低到高'
        },
        {
          value: 3,
          label: '售价-从高到低'
        },
        {
          value: 4,
          label: '售价-从低到高'
        },
        {
          value: 5,
          label: '上架时间-从近到远'
        },
        {
          value: 6,
          label: '下架时间-从近到远'
        },
        {
          value: 0,
          label: '手动排序'
        }
      ],
      title: '新增活动',
      timestamp: 0,
      activityForm: {
        productSortTypeShow: 1,
        backgroundImageUrl: '',
        description: '',
        endTime: '',
        name: '',
        startTime: '',
        showStyle: 1,
        sortNo: 1,
        displayStatus: 1,
        /// 这是新增的
        productShowNum: 0,
        productConnectWay: 1,
        autoConnectType: 1,
        filterDays: 0,
        filterNum: 0,
        miniPageShowNum: 0,
        hideSoldOutProduct: 1
        // routerId: ''
      },
      selectType: 1,
      list: [],
      isEdit: false,
      Dialog: false,
      firstRules: []
    }
  },
  methods: {
    getRules() {
      this.firstRules = this.$initRules([
        {
          label: '活动背景图',
          value: 'backgroundImageUrl',
          type: 'upload',
          required: true
        },
        {
          label: '活动描述',
          value: 'description',
          type: 'input',
          required: true
        },

        {
          label: '活动名称',
          value: 'name',
          type: 'input',
          required: true
        },

        {
          label: '活动的时间',
          value: 'startTime',
          type: 'input',
          required: true
        }
      ])
    },
    handleTimeChange() {
      if (this.time) {
        this.activityForm.startTime = this.time[0]
        this.activityForm.endTime = this.time[1]
      } else {
        this.activityForm.startTime = ''
        this.activityForm.endTime = ''
      }
    },
    edit(row) {
      if (row) {
        this.title = '编辑活动'
        this.activityForm = { ...row }
        this.isEdit = true
        this.time = [row.startTime, row.endTime]
      } else {
        this.time = []
        this.activityForm = {
          backgroundImageUrl: '',
          description: '',
          endTime: '',
          name: '',
          startTime: '',
          showStyle: 1,
          sortNo: 1,
          productSortTypeShow: 1,
          displayStatus: 1,
          productShowNum: 0,
          productConnectWay: 1,
          autoConnectType: 1,
          filterDays: 0,
          filterNum: 0,
          miniPageShowNum: 0,
          hideSoldOutProduct: 1
          // routerId: ''
        }
        this.title = '新增活动'
        this.isEdit = false
      }
      this.Dialog = true
    },
    confirm() {
      this.$refs['activityForm'].validate((valid, validObj) => {
        if (valid) {
          if (this.isEdit) {
            const { productNum, tenantId, ...payload } = this.activityForm
            this.editActivity('putActivity', payload, '修改成功')
          } else {
            this.editActivity('postActivity', this.activityForm, '新增成功')
          }
        } else {
          this.$message.error(validObj[Object.keys(validObj)[0]][0].message)
        }
      })
    },
    editActivity(url, params, text) {
      const loading = this.$loading({
        lock: true,
        text: '正在操作，请稍候',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.$api.product
        .product(url, params, {
          headers: {
            ['Bm-Area-Id']: this.areaId
          }
        })
        .then(res => {
          this.$message.success(text)
          this.Dialog = false
          this.getList()
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
        .finally(() => {
          loading.close()
        })
    },
    // 操作
    relationProduct(row, type, editType = 'add') {
      if (type === 'classify') {
        // 关联分类
        this.$router.push({
          path: '/product/activity/relateClassify',
          query: {
            id: row.id,
            areaId: this.areaId
          }
        })
      } else {
        this.$router.push({
          path: '/product/activity/relateProduct',
          query: {
            id: row.id,
            businessDistrictId: row.areaId,
            editType: editType,
            areaId: row.areaId,
            productSortTypeShow: row.productSortTypeShow
          }
        })
      }
    },
    // 重置搜索
    resetSearch() {
      this.searchValue = {
        name: ''
      }
      this.getList()
    },

    // 搜索活动
    getList(val) {
      if (this.areaId == -1) {
        return
      }
      this.loading = true
      this.currentPage = val === true ? this.currentPage : 1

      this.$http
        .get('/boom-center-admin-service/sysAdmin/productGroup/findPage', {
          params: {
            name: this.searchValue.name,
            page: this.currentPage,
            size: this.pageSize
          },
          headers: {
            ['Bm-Area-Id']: this.areaId
          }
        })

        .then(res => {
          this.list = res.list
          this.total = res.total
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  mounted() {
    if (this.$store.getters['user/currentAreaId'] == -1 && this.$store.getters['user/userAreaInfoListWithoutGlobal']) {
      this.areaId = this.$store.getters['user/userAreaInfoListWithoutGlobal'][0].id
    } else {
      this.areaId = sessionStorage.currentAreaId
    }
    this.getRules()
    this.getList()
  }
}
</script>

<style lang="less" scoped>
.activityDialog {
  .el-select,
  .el-input-number,
  .el-input {
    width: 200px;
  }
  .tips {
    color: #999;
  }
}

.avatar {
  width: 30px;
  height: 30px;
  vertical-align: middle;
}

.tableContainer {
  .text {
    margin-right: 10px;
  }
  .city {
    width: 100px;
  }
}
</style>
